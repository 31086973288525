var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-in bg-gray-3"},[_c('a-row',{staticStyle:{"margin-top":"80px"},attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form pt-20 mt-0",attrs:{"span":24,"md":12,"lg":12,"xl":12}},[_c('a-card',{staticClass:"card-info px-3 mx-0 pt-20",attrs:{"bordered":false}},[_c('a-row',{attrs:{"gutter":[24,24]}},[_c('a-col',{attrs:{"span":24,"md":24,"lg":24}},[_c('a-row',{staticClass:"text-center"},[_c('a-col',{attrs:{"lg":24}},[_c('img',{staticClass:"mb-0 pb-0",staticStyle:{"border-radius":"20px"},attrs:{"src":require("@/assets/images/logo.png"),"alt":"","width":"180px","height":"60px"}})])],1),_c('h3',{staticClass:"mb-0 mt-3 text-center"},[_vm._v("Create Account!")]),_c('p',{staticClass:"text-muted text-center mt-0 pt-0"},[_vm._v("Enter your details to create account")]),(_vm.error.show)?_c('a-alert',{attrs:{"message":_vm.error.message,"type":"error","closable":"","after-close":_vm.handleClose,"show-icon":""}}):_vm._e(),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-row',{staticClass:"pt-4"},[_c('a-col',{attrs:{"span":24}},[_c('p',{staticClass:"description py-0 my-0 mb-1"},[_vm._v("Pharmacy Details")])]),_c('a-col',{attrs:{"span":24}},[_c('a-divider',{staticClass:"mt-1 pt-0 mb-0"})],1)],1),_c('a-row',{attrs:{"gutter":[24,24]}},[_c('a-col',{attrs:{"span":24,"sm":24,"md":24,"lg":24}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'name',
										{ rules: [{ required: true, message: 'Please enter name of the pharmacy!' }] },
										]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'name',\n\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter name of the pharmacy!' }] },\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":"Name of the pharmacy"}})],1)],1)],1),_c('a-row',{staticClass:"my-0 py-0",attrs:{"gutter":[24,24]}},[_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":12,"md":12,"lg":12}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Region","placeholder":"Select Region","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'regionUuid',
												{ rules: [{ required: true, message: 'Please select region!' }] },]),expression:"[ 'regionUuid',\n\t\t\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please select region!' }] },]"}],staticClass:"py-0 my-0",attrs:{"placeholder":"Select Region"},on:{"change":_vm.onRegionChange}},_vm._l((_vm.regions),function(option){return _c('a-select-option',{key:option.name,attrs:{"value":option.uuid}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1)],1),_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":12,"md":12,"lg":12}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"District","placeholder":"Select District","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'districtUuid',
												{ rules: [{ required: true, message: 'Please select the district!' }] },]),expression:"[ 'districtUuid',\n\t\t\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please select the district!' }] },]"}],attrs:{"placeholder":"Select District"},on:{"change":_vm.onDistrictChange}},_vm._l((_vm.districts),function(option){return _c('a-select-option',{key:option.name,attrs:{"value":option.uuid}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1)],1)],1),_c('a-row',{staticClass:"pt-5 pb-2 mb-0"},[_c('a-col',{attrs:{"span":24}},[_c('p',{staticClass:"description py-0 my-0 mb-1"},[_vm._v("Personal Details")])]),_c('a-col',{attrs:{"span":24}},[_c('a-divider',{staticClass:"mt-1 pt-0 mb-0 pb-0"})],1)],1),_c('a-row',{attrs:{"gutter":[24,24]}},[_c('a-col',{attrs:{"span":24,"sm":12,"md":12,"lg":12}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"First Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'firstName',
										{ rules: [{ required: true, message: 'Please enter your first name!' }] },
										]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'firstName',\n\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter your first name!' }] },\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":"First Name"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12,"md":12,"lg":12}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Last Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'lastName',
										{ rules: [{ required: true, message: 'Please enter your last name!' }] },
										]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'lastName',\n\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter your last name!' }] },\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"text","placeholder":"Last Name"}})],1)],1)],1),_c('a-row',{staticClass:"mt-0 pt-0",attrs:{"gutter":[24,24]}},[_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":12,"md":12,"lg":12}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Phone Number","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											'phoneCopy',
											{ rules: [{ required: true, message: 'Please enter your phone number!' }] },
											]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\t'phoneCopy',\n\t\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter your phone number!' }] },\n\t\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Eg: 0621314682"}})],1)],1),_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":12,"md":12,"lg":12}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Email Address","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
											'email',
											{ rules: [{ message: 'Please enter your email address!' }] },
											]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\t'email',\n\t\t\t\t\t\t\t\t\t\t\t{ rules: [{ message: 'Please enter your email address!' }] },\n\t\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Eg: john@gmail.com"}})],1)],1)],1),_c('a-row',{staticClass:"mt-0 pt-0",attrs:{"gutter":[24,24]}},[_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":12,"md":12,"lg":12}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'password',
										{ rules: [{ required: true, message: 'Please enter your password!' }] },
										]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'password',\n\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter your password!' }] },\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"}})],1)],1),_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":12,"md":12,"lg":12}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Confirm Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'passwordConfirmation',
										{ rules: [{ required: true, message: 'Please enter your confirmation password!' }] },
										]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'passwordConfirmation',\n\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter your confirmation password!' }] },\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Confirm Password"}})],1)],1)],1),_c('a-row',{staticClass:"my-0 py-0",attrs:{"gutter":[24,24]}},[_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":12,"md":12,"lg":12}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Gender","placeholder":"Select Gender","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'gender',
												{ rules: [{ required: true, message: 'Please select the gender!' }] },]),expression:"[ 'gender',\n\t\t\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please select the gender!' }] },]"}],attrs:{"placeholder":"Select Gender"}},_vm._l((_vm.genders),function(option){return _c('a-select-option',{key:option.name,attrs:{"value":option.uuid}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1)],1)],1),_c('a-form-item',{staticClass:"mt-4"},[_c('a-button',{staticClass:"login-form-button mt-10 text-white",attrs:{"loading":_vm.isLoading,"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN UP ")])],1),_c('a-row',{staticClass:"pt-10 pb-10",attrs:{"gutters":24}},[_c('a-col',{staticClass:"text-center pb-5",attrs:{"span":24}},[_vm._v(" Already have an account? "),_c('a',{staticClass:"text-primary",staticStyle:{"text-decoration":"none !important"},attrs:{"href":`/`}},[_c('strong',[_vm._v("Sign in")])])])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }