<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 80px;">

			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-form pt-20 mt-0">
				
				<a-card :bordered="false" class="card-info px-3 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						
						<a-col :span="24" :md="24" :lg="24" class="">
							<a-row class="text-center">
								<a-col :lg="24">
									<!-- <img src="../../public/images/icon.png" alt="" width="80px" height="80px" style="border-radius: 20px;">  -->
									<img src="@/assets/images/logo.png" alt="" class="mb-0 pb-0" width="180px" height="60px" style="border-radius: 20px;"> 
								</a-col>
							</a-row>
							<h3 class="mb-0 mt-3 text-center">Create Account!</h3>
							<p class="text-muted text-center mt-0 pt-0">Enter your details to create account</p>

							<a-alert
								v-if="error.show"
								:message="error.message"
								type="error"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-form
								id="components-form-demo-normal-login"
								:form="form"
								class="login-form"
								@submit="handleSubmit"
								:hideRequiredMark="true">

								<a-row class="pt-4">
									<a-col :span="24">
										<p class="description py-0 my-0 mb-1">Pharmacy Details</p>
									</a-col>
									
									<a-col :span="24">
										<a-divider class="mt-1 pt-0 mb-0"/>
									</a-col>
								</a-row>


								<a-row :gutter="[24,24]">
									<a-col :span="24" :sm="24" :md="24" :lg="24">
										<a-form-item class="py-0 my-0" label="Name" :colon="false">
											<a-input
											v-decorator="[
											'name',
											{ rules: [{ required: true, message: 'Please enter name of the pharmacy!' }] },
											]" type="text" placeholder="Name of the pharmacy" />
										</a-form-item>
									</a-col>

									<!-- <a-col :span="24" :sm="12" :md="12" :lg="12">
										<a-form-item class="py-0 my-0" label="TIN Number" :colon="false">
											<a-input
											v-decorator="[
											'tinNumber',
											{ rules: [] },
											]" type="text" placeholder="TIN Number" />
										</a-form-item>
									</a-col> -->
								</a-row>


								<a-row :gutter="[24,24]" class="my-0 py-0">
									<!-- <a-col :span="24" :sm="12" :md="12" :lg="12" class="mt-0 pt-0">
										<a-form-item class="py-0 my-0" label="FIN number (optional)" :colon="false">
											<a-input
											v-decorator="[
											'finNumber',
											{ rules: [] },
											]" type="text" placeholder="FIN Number" />
										</a-form-item>
									</a-col> -->



									<a-col :span="24" :sm="12" :md="12" :lg="12" class="mt-0 pt-0">
										<a-form-item 
											class="py-0 my-0"
											label="Region"
											placeholder="Select Region"
											:colon="false">
											<a-select placeholder="Select Region" 
												class="py-0 my-0"
												v-decorator="[ 'regionUuid',
													{ rules: [{ required: true, message: 'Please select region!' }] },]"
												@change="onRegionChange">
												<a-select-option v-for="option in regions"
													:value="option.uuid"
													:key="option.name">
													{{ option.name }}
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>

									<a-col :span="24" :sm="12" :md="12" :lg="12" class="mt-0 pt-0">
										<a-form-item 
											class="py-0 my-0"
											label="District"
											placeholder="Select District"
											:colon="false">
											<a-select placeholder="Select District" 
												v-decorator="[ 'districtUuid',
													{ rules: [{ required: true, message: 'Please select the district!' }] },]"
												@change="onDistrictChange">
												<a-select-option v-for="option in districts"
													:value="option.uuid"
													:key="option.name">
													{{ option.name }}
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>
									
								</a-row>


								<!-- <a-row :gutter="[24,24]" class="my-0 py-0">
									
									

									<a-col :span="24" :sm="12" :md="12" :lg="12" class="mt-0 pt-0">
										<a-form-item 
											class="py-0 my-0"
											label="Ward (optional)"
											placeholder="Select Ward"
											:colon="false">
											<a-select placeholder="Select Ward" 
												v-decorator="[ 'wardUuid',
													{ rules: [] },]">
												<a-select-option v-for="option in wards"
													:value="option.uuid"
													:key="option.name">
													{{ option.name }}
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>
								</a-row> -->


								<a-row class="pt-5 pb-2 mb-0">
									<a-col :span="24">
										<p class="description py-0 my-0 mb-1">Personal Details</p>
									</a-col>
									
									<a-col :span="24">
										<a-divider class="mt-1 pt-0 mb-0 pb-0"/>
									</a-col>
								</a-row>

								<a-row :gutter="[24,24]">
									<a-col :span="24" :sm="12" :md="12" :lg="12">
										<a-form-item class="py-0 my-0" label="First Name" :colon="false">
											<a-input
											v-decorator="[
											'firstName',
											{ rules: [{ required: true, message: 'Please enter your first name!' }] },
											]" type="text" placeholder="First Name" />
										</a-form-item>
									</a-col>

									<a-col :span="24" :sm="12" :md="12" :lg="12">
										<a-form-item class="py-0 my-0" label="Last Name" :colon="false">
											<a-input
											v-decorator="[
											'lastName',
											{ rules: [{ required: true, message: 'Please enter your last name!' }] },
											]" type="text" placeholder="Last Name" />
										</a-form-item>
									</a-col>
								</a-row>


								<a-row :gutter="[24,24]" class="mt-0 pt-0">
									<a-col :span="24" :sm="12" :md="12" :lg="12" class="mt-0 pt-0">
										<a-form-item class="py-0 my-0" label="Phone Number" :colon="false">
											<a-input 
												v-decorator="[
												'phoneCopy',
												{ rules: [{ required: true, message: 'Please enter your phone number!' }] },
												]" placeholder="Eg: 0621314682" />
										</a-form-item>
									</a-col>

									<a-col :span="24" :sm="12" :md="12" :lg="12" class="mt-0 pt-0">
										<a-form-item class="py-0 my-0" label="Email Address" :colon="false">
											<a-input 
												v-decorator="[
												'email',
												{ rules: [{ message: 'Please enter your email address!' }] },
												]" placeholder="Eg: john@gmail.com" />
										</a-form-item>
									</a-col>

								</a-row>
								

								<a-row :gutter="[24,24]" class="mt-0 pt-0">
									<a-col :span="24" :sm="12" :md="12" :lg="12" class="mt-0 pt-0">
										<a-form-item class="py-0 my-0" label="Password" :colon="false">
											<a-input
											v-decorator="[
											'password',
											{ rules: [{ required: true, message: 'Please enter your password!' }] },
											]" type="password" placeholder="Password" />
										</a-form-item>
									</a-col>

									<a-col :span="24" :sm="12" :md="12" :lg="12" class="mt-0 pt-0">
										<a-form-item class="py-0 my-0" label="Confirm Password" :colon="false">
											<a-input
											v-decorator="[
											'passwordConfirmation',
											{ rules: [{ required: true, message: 'Please enter your confirmation password!' }] },
											]" type="password" placeholder="Confirm Password" />
										</a-form-item>
									</a-col>
								</a-row>


								<a-row :gutter="[24,24]" class="my-0 py-0">
									
									<a-col :span="24" :sm="12" :md="12" :lg="12" class="mt-0 pt-0">
										<a-form-item 
											class="py-0 my-0"
											label="Gender"
											placeholder="Select Gender"
											:colon="false">
											<a-select placeholder="Select Gender" 
												v-decorator="[ 'gender',
													{ rules: [{ required: true, message: 'Please select the gender!' }] },]">
												<a-select-option v-for="option in genders"
													:value="option.uuid"
													:key="option.name">
													{{ option.name }}
												</a-select-option>
											</a-select>
										</a-form-item>
									</a-col>

									
								</a-row>


								<a-form-item class="mt-4">
									<a-button :loading="isLoading" type="primary" block html-type="submit" class="login-form-button mt-10 text-white" >
										SIGN UP
									</a-button>
								</a-form-item>


								<a-row :gutters="24" class="pt-10 pb-10">
									<a-col :span="24" class="text-center pb-5">
										Already have an account? <a class="text-primary" style="text-decoration: none !important;" :href="`/`">
											<strong>Sign in</strong>
										</a>
									</a-col>
								</a-row>

							</a-form>
						</a-col>
					</a-row>
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
	export default ({
		
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" 
				// form: null,

				error: {
					message: null,
					show: false,
				},

				countryCode: '255',
				regions: [],
				districts: [],
				wards: [],
				genders: [
					{ uuid: "Male", name: "Male" },
					{ uuid: "Female", name: "Female" },
				],

				notify: {
					text: null,
					type: null,
				},

				isLoading: false,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		created() {
			this.getRegions()
		},
		methods: {

			onSelectCountry({dialCode}) {
				this.countryCode = dialCode;
			},

			handleClose() {
				this.error.show = false;
			},


			onRegionChange(event) {
                this.districts = [];
                this.wards = [];

				// eslint-disable-next-line no-console
				console.log(this.form)

				this.form.setFieldsValue({
					districtUuid: '',
					wardUuid: '',
				});

                this.getDistricts(event);
            },

            onDistrictChange(event) {
                this.wards = []

				this.form.setFieldsValue({
					wardUuid: '',
				});

                this.getWards(event)
            },


			getRegions() {
                
                const currentPage = 1;
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/regions/?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    this.regions = response.data.data;
                    
                }).catch((error) => {
                   
                    this.notify.text =  error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "danger"
                    this.$notify(this.notify)

                });
            },


            getDistricts(regionUuid) {

                const currentPage = 1;
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/districts/region/${regionUuid}?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.districts = response.data.data;
                    }
                }).catch((error) => {
					
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "danger"
                    this.$notify(this.notify)

                });
            },

            
            getWards(districtUuid) {

                const currentPage = 1;
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/wards/district/${districtUuid}?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.wards = response.data.data;
                    }
					
                }).catch((error) => {
					
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "danger"
                    this.$notify(this.notify)

                });
            },

			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.error.show = false;
						this.error.message = null;
						this.isLoading = true;

						// eslint-disable-next-line no-console
						// console.log(values)

						let payload = values;
						
						if(payload.phoneCopy.substring(0, 4).includes(this.countryCode) && !payload.phoneCopy.includes("+")) {
							payload.phone = "+" + payload.phoneCopy;
						}

						else if(payload.phoneCopy.substring(0, 4).includes(this.countryCode) && payload.phoneCopy.includes("+")) {
							payload.phone =  payload.phoneCopy;
						}

						else if(payload.phoneCopy[0] == "0" || payload.phoneCopy[0] == 0) {
							payload.phone = "+" + this.countryCode + "" + payload.phoneCopy.substring(1)
						}

						else if(payload.phoneCopy[0] != "0" || payload.phoneCopy[0] != 0) {
							payload.phone = "+" + this.countryCode + "" + payload.phoneCopy
						}

						else {
							payload.phone = payload.phoneCopy;
						}


						let url = `${this.$BACKEND_URL}/auth/register-account`;
						
						this.$AXIOS.post(url, payload).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {
								// await localStorage.setItem("agent_token", response.data._id)
								await localStorage.setItem("zegeUser", JSON.stringify(response.data.user))

								this.notify.type = 'success'
								this.notify.text = response.data.message

								this.$notify(this.notify)
								
								this.isLoading = false;

								this.$router.push(`/verify-account`);
							}
							this.isLoading = false
						}).catch((error) => {
							this.isLoading = false;

							// eslint-disable-next-line no-console
						// console.log(error)
							
							this.error.message = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
							this.error.show = true
						});
					}
				});
			},

		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
</style>
<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->
